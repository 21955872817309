const redirectAuth = () => {
  const setCookie = (cname: string, cvalue: string, exdays?: number) => {
    const d = new Date()
    d.setTime(d.getTime() + ((exdays ?? 1) * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }
  setCookie('redirect_url', window.location.href)
  const url : (string | Location) = `${process.env.VUE_APP_LOGIN_URL}?redirect=${encodeURIComponent(`${process.env.VUE_APP_AUTH_REDIRECT_URL}`)}`

  window.location = <any> url
}

export { redirectAuth }
