









































































































































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import { phone } from 'phone'
import { ShopManagerItem, Tags } from '../types/shop-manager'
import { Response } from '../types/response'
import { SelectedItem, DeliveryDate, OrderCreateResponse, OrderByUidResponse } from '../types/order'
import { NotifyBadgeType } from '../types/notification-badge'
import { VForm } from '../types/validation'
import { utcToDutch } from '../helpers/timezones'

export interface FormRules {
  required: (value: any) => true | string,
  requiredObject: (value: any) => true | string,
  email: (value: any) => true | string,
  phone: (value: any) => true | string
}

type DisplayDeliveryDate = {
  dateDisplay?: string,
  deliveryTimeblockDisplay?: string,
  closingTimeDisplay?: string
} & DeliveryDate

export default Vue.extend({
  name: 'CreateOrders',
  data () {
    return {
      itemsGroup: [] as Array<Array<ShopManagerItem>>,
      form: {
        items: [] as SelectedItem[],
        tag: {} as Tags,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        street: '',
        number: null,
        addition: '',
        postal: '',
        deliveryDate: {} as DisplayDeliveryDate,
        isAmbassadorTabletOrder: false,
        timeblock: {} as DeliveryDate
      },
      loading: false as boolean,
      items: [] as ShopManagerItem[],
      itemSelected: {} as ShopManagerItem,
      amountSelected: 0 as number,
      itemEdit: {} as ShopManagerItem,
      amountEdit: 0 as number,
      loadingBtnTag: false as boolean,
      editIndex: null as string | null,
      loadingBtnDeliveryDate: false as boolean,
      loadingBtnSave: false as boolean,
      deliveryDates: [] as DisplayDeliveryDate[],
      orderUid: '' as string,
      loadingSearchOrder: false as boolean,
      sourceOrder: null as string | null,
      showTimeBlock: false as boolean,
      resolvedLocation: null as any,
      rules: {
        required: value => !!value || 'Required.',
        requiredObject: value => !!Object.keys(value).length || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        phone: value => {
          const rulePhone = phone(value, {
            validateMobilePrefix: true
          })
          return rulePhone.isValid || 'Invalid phone number.'
        }
      } as FormRules,
      valid: false as boolean
    }
  },
  mounted () {
    this.getTags()
  },
  watch: {
    'form.street': {
      handler () {
        this.showTimeBlock = false
        this.resolvedLocation = null
      },
      deep: true
    },
    'form.number': {
      handler () {
        this.showTimeBlock = false
        this.resolvedLocation = null
      },
      deep: true
    },
    'form.addition': {
      handler () {
        this.showTimeBlock = false
        this.resolvedLocation = null
      },
      deep: true
    },
    'form.postal': {
      handler () {
        this.showTimeBlock = false
        this.resolvedLocation = null
      },
      deep: true
    },
    orderUid (value) {
      this.orderUid = value.toUpperCase()
    }
  },
  computed: {
    ...mapGetters(['shopTags', 'userRole']),
    timeBlocksAvailable () : DeliveryDate[] {
      if (!this.form.deliveryDate || !this.form.deliveryDate.date) {
        return []
      }
      return this.deliveryDates.filter(element => {
        return element.dateDisplay === this.form.deliveryDate.dateDisplay
      })
    },
    tagsAvailable () : Tags[] {
      if (this.sourceOrder !== 'admin' && this.orderUid.length && this.form.items.length) {
        return this.shopTags.filter((tag: Tags) => tag.uid === this.form.items[0].item.tagUid)
      }
      return this.shopTags
    }
  },
  methods: {
    getTags () : void {
      this.loading = true
      this.$api.get('/shop-manager/tags').then(({ data }: Response<Tags[]>) => {
        const tags : Tags[] = data.data.tags
        this.$store.dispatch('getShopTags', { tags })
      }).finally(() => {
        this.loading = false
      })
    },
    getItems () : void {
      this.loadingBtnDeliveryDate = true
      this.$api.get(`/shop-manager/items/${this.form.tag.uid}`).then(({ data }: Response<ShopManagerItem[]>) => {
        const items : ShopManagerItem[] = data.data.items
        this.items = items
      }).finally(() => {
        this.loadingBtnDeliveryDate = false
      })
    },
    addItem () : void {
      if (this.amountSelected <= 0) {
        this.$notify({
          message: 'Can\'t be less than 0',
          type: NotifyBadgeType.WARNING
        })
        return
      }
      const itemAlreadySelected = this.form.items.filter((element: SelectedItem) => element.itemUid === this.itemSelected.uid)
      if (itemAlreadySelected.length) {
        this.$notify({
          message: 'You already added this item please edit instead',
          type: NotifyBadgeType.WARNING
        })
        return
      }
      const selected: SelectedItem = {
        item: this.itemSelected,
        amount: Math.floor(this.amountSelected),
        itemUid: this.itemSelected.uid
      }
      this.form.items.push(selected)
    },
    editSelectedItem (item: SelectedItem) {
      this.itemEdit = item.item
      this.amountEdit = item.amount
      this.editIndex = item.itemUid
    },
    saveEditItem () : void {
      if (this.amountEdit <= 0) {
        this.$notify({
          message: 'Can\'t be less than 0',
          type: NotifyBadgeType.WARNING
        })
        return
      }
      const selected: SelectedItem = {
        item: this.itemEdit,
        amount: Math.floor(this.amountEdit),
        itemUid: this.itemEdit.uid
      }

      if (this.editIndex) {
        this.form.items.forEach((element, index) => {
          if (element.itemUid === this.editIndex) {
            this.form.items[index] = selected
          }
        })
        this.editIndex = null
      }
    },
    deleteSelectedItem (index: number) {
      this.form.items.splice(index, 1)
    },
    getDeliveryDates () {
      if (!this.form.tag.uid) {
        this.$notify({
          message: 'Please select the tag to get the timeblock!',
          type: NotifyBadgeType.WARNING
        })
        return
      }

      this.loadingBtnDeliveryDate = true
      const request = {
        postalCode: this.form.postal,
        houseNumber: this.form.number,
        addition: this.form.addition
      }
      return this.$api.get(`/v2/delivery-date/${this.form.tag.uid}/location/`, { params: request }).then(({ data }: Response<DeliveryDate[]>) => {
        const items : DeliveryDate[] = data.data.dates
        this.deliveryDates = items.map(e => {
          return {
            ...e,
            dateDisplay: utcToDutch(e.date).format('DD-MM-YYYY'),
            deliveryTimeblockDisplay: `${utcToDutch(e.startDeliveryTime).format('HH:mm')} - ${utcToDutch(e.endDeliveryTime).format('HH:mm')}`
          }
        })
        this.resolvedLocation = data.data.location
        this.showTimeBlock = true
        this.getItems()
      })
        .finally(() => {
          this.loadingBtnDeliveryDate = false
        })
    },
    save () {
      const formValidation = this.$refs.form as VForm
      this.valid = formValidation.validate()
      if (!this.valid) {
        return
      }
      if (this.orderUid.length) {
        this.updateOrder()
      } else {
        this.createOrder()
      }
    },
    createOrder () {
      this.loadingBtnSave = true
      const request = {
        user: {
          name: this.userRole.name,
          email: this.userRole.email
        },
        ...this.form
      }
      this.$api.post('/order/create-order', request).then(({ data }: Response<OrderCreateResponse>) => {
        const order = data.data.order
        this.$notify({
          message: `The order was successfully created! Order UID: ${order.uid}`,
          type: NotifyBadgeType.SUCCESS
        })
        this.clearFields()
      }).finally(() => {
        this.loadingBtnSave = false
      })
    },
    updateOrder () {
      this.loadingBtnSave = true
      const request = {
        orderUid: this.orderUid,
        source: this.sourceOrder,
        user: {
          name: this.userRole.name,
          email: this.userRole.email
        },
        ...this.form
      }
      this.$api.put('/order/', request).then(({ data }: Response<OrderCreateResponse>) => {
        const order = data.data.order
        this.$notify({
          message: `The order was successfully updated! Order UID: ${order.uid}`,
          type: NotifyBadgeType.SUCCESS
        })
        this.clearFields()
      }).finally(() => {
        this.loadingBtnSave = false
      })
    },
    clearFields () {
      this.form = {
        items: [] as SelectedItem[],
        tag: {} as Tags,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        street: '',
        number: null,
        addition: '',
        postal: '',
        deliveryDate: {} as DeliveryDate,
        isAmbassadorTabletOrder: false,
        timeblock: {} as DeliveryDate
      }
      this.items = [] as ShopManagerItem[]
      this.itemSelected = {} as ShopManagerItem
      this.amountSelected = 0 as number
      this.editIndex = null as string | null
      this.deliveryDates = [] as DeliveryDate[]
      this.orderUid = '' as string
      this.showTimeBlock = false as boolean
      this.resolvedLocation = null as any
      (this.$refs.form as VForm).resetValidation()
    },
    getOrderByUid () {
      this.loadingSearchOrder = true
      this.$api.get(`/v2/order/${this.orderUid}`).then(({ data }: Response<OrderByUidResponse>) => {
        const { order } = data.data
        if (order.hasRouteInProgress) {
          this.$notify({
            message: 'The order has a route in progress',
            type: NotifyBadgeType.WARNING
          })
        } else if (order.orderIsRescheduled) {
          this.$notify({
            message: 'The order has reschedule status',
            type: NotifyBadgeType.WARNING
          })
        } else {
          this.form.items = order.basket
          this.form.firstName = order.consumer.firstName
          this.form.lastName = order.consumer.lastName
          this.form.phoneNumber = order.consumer.phone
          this.form.email = order.consumer.email
          this.form.addition = order.location.addition
          this.form.number = order.location.number
          this.form.postal = order.location.postal
          this.form.street = order.location.street
          this.sourceOrder = order.source
          this.getDeliveryDates().then(() => {
            if (order.deliveryDate && order.deliveryDate.UID) {
              this.form.deliveryDate = order.deliveryDate
            }
          })
        }
      }).finally(() => {
        this.loadingSearchOrder = false
      })
    }
  }
})
