




















































































































































































import { mapGetters } from 'vuex'
import Vue from 'vue'
import defaultImg from '@/assets/no-image.png'
import DialogImages from './DialogImages.vue'
import { ShopManagerItem, DialogItemForm, ItemPriceForm, ShopManagerImage, Tags, ItemIdentifier, Category } from '../../types/shop-manager'
import { Response } from '../../types/response'
import { NotifyBadgeType } from '../../types/notification-badge'

export default Vue.extend({
  name: 'DialogItem',
  props: {
    item: {
      type: Array as () => ShopManagerItem[],
      required: false
    },
    dialogItem: {
      type: Boolean,
      required: true
    }
  },
  components: {
    DialogImages
  },
  data () {
    return {
      dialog: false as boolean,
      form: {
        name: '',
        tagUids: [],
        category: {
          id: 0,
          name: ''
        },
        itemInfo: {
          disabled: false,
          price: 0,
          deposit: 0,
          vat: 0,
          description: ''
        },
        image: {
          uid: '',
          url: defaultImg
        }
      } as DialogItemForm,
      panel: [] as number[],
      categories: [] as Category[],
      itemsPrice: [] as ItemPriceForm[],
      dialogImage: false as boolean,
      loadingSaveBtn: false as boolean
    }
  },
  mounted () {
    this.getCategories()
  },
  watch: {
    dialogItem (value) {
      if (value) {
        this.dialog = value
        if (this.item && this.item.length) {
          this.fillEditedItem(this.item)
        }
      }
    },
    'form.tagUids': {
      handler (value : string[]) {
        const objExample = {
          disabled: false,
          price: 0,
          deposit: 0,
          vat: 0,
          description: ''
        }
        if (this.isCreateItem) {
          this.itemsPrice = []
          value.forEach((element) => {
            this.itemsPrice.push({
              tagUid: element,
              ...objExample
            })
          })
        } else {
          value.forEach((element, index) => {
            if (index + 1 > this.itemsPrice.length) {
              this.itemsPrice.push({
                tagUid: element,
                ...objExample
              })
            }
          })
        }
      },
      deep: true
    },
    dialog (value) {
      if (!value) {
        this.closeModal()
      }
    }
  },
  computed: {
    isCreateItem () {
      return !(this.item && this.item.length)
    },
    ...mapGetters(['shopTags', 'userRole']),
    hasPrivilege () : boolean {
      return !!(Object.keys(this.userRole).length && !this.userRole.privileges.admin)
    },
    tagsAvailable () : Tags[] {
      return this.shopTags.map((element: Tags) => {
        if (this.form.tagUids.includes(element.uid)) {
          return {
            disabled: true,
            ...element
          }
        }
        return { ...element }
      })
    }
  },
  methods: {
    closeModal () : void {
      this.dialog = false
      this.clearFields()
      this.$emit('update:dialogItem', false)
    },
    fillEditedItem (value : ShopManagerItem[]) : void {
      this.form.name = value[0].name
      this.form.category = this.categories.filter(element => element.name === value[0].category)[0]
      this.form.tagUids = value.map(element => element.tagUid)
      this.form.image.uid = value[0].assetUid
      this.form.image.url = value[0].imageUrl
      this.setDifferentInfo(value)
    },
    setDefaultImg (e: any) : void {
      e.target.src = defaultImg
    },
    hasDifferentInfo (value : ShopManagerItem[]) : boolean {
      const prices = value.map(element => element.price)
      const deposits = value.map(element => element.deposit)
      const vats = value.map(element => element.vat)
      const descriptions = value.map(element => element.description)

      return this.checkDiff(prices) || this.checkDiff(deposits) || this.checkDiff(vats) || this.checkDiffStringType(descriptions)
    },
    checkDiff (array : number[]) : boolean {
      return new Set(array).size !== 1
    },
    checkDiffStringType (array : string[]) : boolean {
      return new Set(array).size !== 1
    },
    setDifferentInfo (value : ShopManagerItem[]) : void {
      this.itemsPrice = []
      value.forEach(element => {
        this.itemsPrice.push({
          tagUid: element.tagUid,
          disabled: element.disabled,
          price: element.price,
          deposit: element.deposit,
          vat: element.vat,
          description: element.description
        })
      })
    },
    clearFields () : void {
      this.form = {
        name: '',
        tagUids: [],
        category: {
          id: 0,
          name: ''
        },
        itemInfo: {
          disabled: false,
          price: 0,
          deposit: 0,
          vat: 0,
          description: ''
        },
        image: {
          uid: '',
          url: defaultImg
        }
      }
      this.itemsPrice = []
    },
    onClickImage (newImage : ShopManagerImage) : void {
      this.form.image = {
        uid: newImage.uid,
        url: newImage.imageUrl
      }
    },
    save () {
      if (this.isCreateItem) {
        this.createItem()
      } else {
        if (this.hasPrivilege) {
          this.toggleItem()
          return
        }
        this.editItem()
      }
    },
    createItem () {
      this.loadingSaveBtn = true
      const request = {
        itemsPrice: this.itemsPrice,
        ...this.form
      }
      this.$api.post('/shop-manager/add-item', request).then(() => {
        this.$emit('onItemCreated')
        this.closeModal()
        this.$notify({
          message: 'Created',
          type: NotifyBadgeType.SUCCESS
        })
      }).finally(() => {
        this.loadingSaveBtn = false
      })
    },
    editItem () {
      this.loadingSaveBtn = true

      const itemIdentifierList: ItemIdentifier[] = this.item.map((element: ShopManagerItem) => {
        return {
          uid: element.uid,
          tagUid: element.tagUid,
          id: element.id
        }
      })

      const request = {
        itemsPrice: this.itemsPrice,
        ...this.form,
        itemIdentifierList
      }

      this.$api.put('/shop-manager/edit-item', request).then(() => {
        this.$emit('onItemCreated')
        this.closeModal()
        this.$notify({
          message: 'Updated',
          type: NotifyBadgeType.SUCCESS
        })
      }).finally(() => {
        this.loadingSaveBtn = false
      })
    },
    getCategories () {
      this.$api.get('/shop-manager/category').then(({ data }: Response<Category[]>) => {
        this.categories = data.data.categories
      })
    },
    getTagName (tagUid: string) {
      return this.shopTags.filter((element: Tags) => element.uid === tagUid)[0].name
    },
    copyToAll (item: ItemPriceForm) {
      this.itemsPrice.forEach((element: ItemPriceForm, index: number) => {
        this.itemsPrice[index].price = item.price
        this.itemsPrice[index].deposit = item.deposit
        this.itemsPrice[index].vat = item.vat
        this.itemsPrice[index].description = item.description
      })
    },
    toggleItem () {
      this.loadingSaveBtn = true

      const request = this.itemsPrice.map((element: ItemPriceForm) => {
        const item: ShopManagerItem = this.item.filter((value: ShopManagerItem) => value.tagUid === element.tagUid)[0]
        return {
          itemUid: item.uid,
          disabled: element.disabled
        }
      })
      this.$api.put('/shop-manager/toggle-item', request).then(() => {
        this.$emit('onItemCreated')
        this.closeModal()
        this.$notify({
          message: 'Updated',
          type: NotifyBadgeType.SUCCESS
        })
      }).finally(() => {
        this.loadingSaveBtn = false
      })
    }
  }
})
