import Vue from 'vue'
import { mapGetters } from 'vuex'
// import { SessionUser, Privileges } from '@turff/sdk'
import { NotifyBadgeType } from '../types/notification-badge'
export interface Privileges {
  manages: boolean,
  drives: boolean,
  admin: boolean
}
export interface UserResponse {
  name: string,
  email: string,
  privileges: Privileges
}

export default Vue.extend({
  created () {
    this.setUserInfo()
  },
  computed: {
    ...mapGetters(['userRole'])
  },
  watch: {
    $route () {
      this.checkPrivileges()
    }
  },
  methods: {
    checkPrivileges () : void {
      const routePrivilege = this.$route?.meta?.privilege
      if (!routePrivilege) {
        return
      }
      let hasPrivilege = false
      routePrivilege.forEach((element: string) => {
        hasPrivilege = this.userRole.privileges[element] || hasPrivilege
      })
      if (!hasPrivilege) {
        this.$router.push('/')
        this.$notify({
          message: 'You don\'t have privileges to access this page.',
          type: NotifyBadgeType.WARNING
        })
      }
    },
    setUserInfo () : void {
      let user: UserResponse
      this.$api.get('/me').then(({ data }: { data: UserResponse}) => {
        user = data
        this.$store.dispatch('getUserRole', { user })
        this.checkPrivileges()
      })
    }
  }
})
