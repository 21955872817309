<template>
  <v-card>
    <v-card-title>
      Open Orders
    </v-card-title>

    <v-card-text>
      <v-col cols="6">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        />
      </v-col>
      <v-data-table
        :headers="headers"
        :items="orders"
        class="elevation-1"
        sort-by="startDeliveryTime"
        :search="search"
        item-key="uid"
        show-select
        :items-per-page="-1"
        hide-default-footer
        v-model="selectedData"
        @click:row="openDialog"
        @input="handleInput"
      >
        <template v-slot:[`item.address`]="{ item }">
          {{ `${item.location.street} ${item.location.number} ${item.location.addition}` }}
        </template>

        <template v-slot:[`item.time_since_order`]="{ item }">
          {{ calcTimeSinceOrder(item.created) }}
        </template>

        <template v-slot:[`item.created`]="{ item }">
          {{ utcToDutch(item.created).format('DD/MM/YYYY, HH:mm') }}
        </template>

        <template v-slot:[`item.startDeliveryTime`]="{ item }">
          {{ formatTimeblockTime(item) }}
        </template>

      </v-data-table>
      <v-dialog
        v-model="dialog.open"
        width="500"
      >
        <v-card v-if="dialog.open">
          <v-card-title class="text-h5 orange darken-3">
            Details {{dialog.row.uid}}
          </v-card-title>

          <v-card-text>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>Consumer:</v-list-item-content>
                <v-list-item-content>{{dialog.row.consumerName}}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Phone:</v-list-item-content>
                <v-list-item-content>{{dialog.row.consumerPhone}}</v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list dense>
              <v-list-item v-for="(item, index) of dialog.row.items" :key="index">
                <v-list-item-content>{{item.name}}:</v-list-item-content>
                <v-list-item-content>{{item.amount}}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog.open = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        fixed
        color="#e95e38"
        fab
        dark
        bottom
        right
        style="color:white"
        @click="createRoutes"
        v-if="selectedData && selectedData.length"
        :loading="loadingRouteBtn"
      >
        <v-icon>mdi-truck-delivery</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-card>
</template>

<script>
import { dayjs, utcToDutch } from '../../helpers/timezones'
import { NotifyBadgeType } from '../../types/notification-badge'

export default {
  name: 'OrdersTable',
  props: {
    date: {
      type: String,
      required: true
    },
    orders: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        { text: 'Order', value: 'uid' },
        { text: 'Timeblock', value: 'startDeliveryTime' },
        { text: 'Time Since Order', value: 'time_since_order' },
        { text: 'Created', value: 'created' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'location.city' },
        { text: 'Postal', value: 'location.postal' }
      ],
      search: '',
      stageText: [],
      dialog: {
        open: false,
        row: null
      },
      openRow: null,
      selectedData: this.selected,
      loadingRouteBtn: false,
      utcToDutch
    }
  },
  watch: {
    selected (value) {
      this.selectedData = value
    }
  },
  methods: {
    formatTimeblockTime (item) {
      if (item.startDeliveryTime && item.endDeliveryTime) {
        const startTime = utcToDutch(item.startDeliveryTime).format('HH:mm')
        const endTime = utcToDutch(item.endDeliveryTime).format('HH:mm')

        const timeblockString = `${startTime} - ${endTime}`

        return timeblockString
      } else {
        return '-'
      }
    },
    handleInput () {
      this.$emit('selected', this.selectedData)
    },
    createRoutes () {
      const orders = this.selectedData.map((o) => {
        return {
          uid: o.uid,
          rank: '',
          fixed: false
        }
      })
      if (!orders.length) {
        this.$notify({
          message: 'No stops selected!',
          type: NotifyBadgeType.WARNING
        })
        return
      }
      this.loadingRouteBtn = true
      this.$api.post('/route', { date: dayjs.utc(this.date).format(), orders })
        .then((response) => {
          this.$notify({
            message: 'The route was successfully created!',
            type: NotifyBadgeType.SUCCESS
          })
          this.$emit('reload')
          this.$emit('onRouteCreated')
          const routeCreated = response.data.route
          this.$router.push(`/route/${routeCreated}`)
          this.$emit('onOpenRoute')
        }).finally(() => {
          this.loadingRouteBtn = false
        })
    },
    openDialog (row) {
      this.dialog.row = row
      this.dialog.open = true
    },
    calcTimeSinceOrder (date) {
      const diffMs = (Date.now() - (new Date(date)).getTime())
      const diffDays = Math.floor(diffMs / 86400000) // days
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes

      if (diffDays > 0) {
        return `${diffDays} days, ${diffHrs} hours`
      } else if (diffHrs > 0) {
        return `${diffHrs} hours, ${diffMins} mins`
      } else {
        return `${diffMins} minutes`
      }
    }
  }

}
</script>

<style scoped>

</style>
