














































































import axios from 'axios'
import Vue from 'vue'
import defaultImg from '@/assets/no-image.png'
import { ShopManagerImage } from '../../types/shop-manager'
import { Response } from '../../types/response'

export interface ShopManagerFile {
  id: number,
  url: string
}

export default Vue.extend({
  name: 'DialogImages',
  props: {
    dialogImage: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      dialog: false as boolean,
      imageUploaded: null as File | null,
      images: [] as ShopManagerImage[],
      loading: false as boolean,
      attrsSkeleton: {
        boilerplate: true,
        elevation: 0
      } as any
    }
  },
  // mounted () {
  //   this.getImages()
  // },
  watch: {
    dialogImage (value) {
      if (value) {
        this.dialog = value
        this.getImages()
      }
    },
    dialog (value) {
      if (!value) {
        this.closeModal()
      }
    }
  },
  methods: {
    closeModal () : void {
      this.dialog = false
      this.$emit('update:dialogImage', false)
    },
    setDefaultImg (e: any) : void {
      e.target.src = defaultImg
    },
    clickImage (image: ShopManagerImage) : void {
      this.closeModal()
      this.$emit('onClickImage', image)
    },
    selectImage (file: File) : void {
      this.imageUploaded = file
    },
    upload () : void {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const file: File = this.imageUploaded!
      const formData = new FormData()
      formData.append('file', file)

      axios({
        method: 'POST',
        url: '/shop-manager/images',
        baseURL: process.env.VUE_APP_BACKEND_URL,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true

      }).then(() => {
        this.getImages()
        this.imageUploaded = null
      })
    },
    getImages () : void {
      this.loading = true
      this.$api.get('/shop-manager/images').then(({ data }: Response<ShopManagerImage[]>) => {
        this.images = data.data.images
      }).finally(() => {
        this.loading = false
      })
    }
  }
})
