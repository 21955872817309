<template>
  <v-container>
    <v-row class="flex-row">
      <v-col cols="12" align-self="start" class="mt-5">
        <v-row>
          <v-btn @click="back"  large elevation="4" dark icon color="error" style="margin-left: 15px">
            <v-icon>fa fa-arrow-left</v-icon>
          </v-btn>
<!--          <v-spacer />-->
<!--          <span v-if="target.status === 'delivered'" style="color: grey; margin:auto">Bezorgd!</span>-->
<!--          <span v-if="target.status === 'rescheduled'" style="color: grey; margin:auto">Niet thuis</span>-->
<!--          <v-spacer />-->
<!--          <v-btn v-if="(target.status === 'delivered' || target.status === 'rescheduled') && locked" @click="locked = false">-->
<!--            <v-icon left>-->
<!--              fa fa-lock-->
<!--            </v-icon>-->
<!--            {{ 'Unlock' }}-->
<!--          </v-btn>-->
<!--          <v-spacer/>-->
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="route.length">
        <v-card>
          <v-card-title>Route: {{uid}}</v-card-title>
          <v-card-subtitle>
            Stops: {{route.length}}<br>
            Lading: <br>
            <div style="border-bottom: solid gray 1px" v-for="(item, index) of Object.keys(total)" :key="index">
              <span style="padding-left: 10px">{{item}}: {{total[item].amount}}</span><br>
              <span style="padding-left: 20px; color: gray; font-size: 12px">{{total[item].description}}</span>
            </div>
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-spacer />
              <v-dialog
                v-model="startDialog"
                persistent
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="isRouteStarted"
                  >
                    Start
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h6">
                    Route start
                  </v-card-title>
                  <v-card-text class="black--text">Are you sure you want to press start? Click OK</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="grey darken-1"
                      text
                      @click="startDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="success"
                      @click="start"
                      :loading="startLoading"
                    >
                      Ok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer />
              <template v-if="userRole.privileges.manages || userRole.privileges.admin">
                <v-dialog
                  v-model="changeDialog"
                  persistent
                  max-width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey"
                      v-bind="attrs"
                      v-on="on"
                      dark
                    >
                      Change
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h6">
                      Edit Route
                    </v-card-title>
                    <v-card-text  v-if="draw">
                      <draggable
                        v-model="route"
                        handle=".handle"
                        class="list-group"
                        tag="ul"
                        draggable=".list-group-item"
                        @start="drag = true"
                        @end="drag = false"
                        :sort="true"
                      >
                        <transition-group type="transition" list-group-item>
                          <li
                            style="list-style-type: none;"
                            class="list-group-item flex-container"
                            v-for="(stop, index) in route"
                            :key="'' + stop.rank + index"
                            :index="stop.rank"
                          >
                            <i
                              style="cursor: pointer; font-size: 20px; margin: 10px"
                              v-bind:class="{'fa fa-lock': stop.fixed, 'fa fa-lock-open': !stop.fixed}"
                              @click="lock(stop)"
                              aria-hidden="true"
                              :key="''+ index + stop.fixed"
                            />
                            <i
                              style="cursor: move; font-size: 20px;margin: 10px"
                              class="fa fa-align-justify handle" />
                            <!--              <font-awesome-icon icon="align-justify" class="handle" />-->
                            <span style="margin: 10px">{{stop.adres.streetdata}} {{stop.adres.postal}}</span>
    <!--                        {{stop}}-->
                          </li>
                        </transition-group>
                      </draggable>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="grey darken-1"
                        text
                        @click="changeDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="success"
                        @click="recalculate"
                        :loading="changeLoading"
                      >
                        Recalculate Route
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer />
              </template>
              <v-btn color="error" @click="end" :loading="endRouteLoading" :disabled="isRouteEnded">
                End
              </v-btn>
              <v-spacer />
              <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="390"
                v-if="userRole.privileges.admin"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Delete Route
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h6">
                    Delete Route
                  </v-card-title>
                  <v-card-text class="black--text">
                    <div class="mb-4">
                      All the orders are going to be changed to PAID status after this you click OK.
                    </div>
                    <div>
                      Are you sure you want to delete the route?
                    </div>
                    <div>
                      <b>This action cannot be reverted!</b>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="grey darken-1"
                      text
                      @click="deleteDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="error"
                      @click="deleteRoute"
                      :loading="deleteLoading"
                    >
                      Ok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer />
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-5">
          <v-card-text>
            <v-divider />
            <v-timeline align-top dense>
              <v-timeline-item v-for="(stop, index) of route" :key="index"
                :fill-dot="stop.done"
                :icon="stop.icon"
              >
                <v-card @click="navigate(index)">
                  <v-card-text>
                    <v-list-item-content>
                      <v-list-item-title v-if="stop.firstTimeSource" class="mb-3">
                        <v-icon color="green accent-4" large>mdi-alert-octagram</v-icon>
                        <span class="black--text">
                          <b>{{ getFirstTimeMessage(stop.firstTimeSource) }}</b>
                        </span>
                      </v-list-item-title>
                      <v-list-item-title :class="{'blurry-text disable-select': !isRouteStarted}">
                        {{stop.adres.streetdata}}, {{stop.adres.city}}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Items: {{stop.total}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="stop.estimated">
                        ETA:
                        {{ formatEstimatedDeliveryTime(stop.estimated) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="stop.delivered" style="white-space: unset;">
                        Delivered: {{ utcToDutch(stop.delivered).format('DD/MM/YYYY HH:mm') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
            <v-divider />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-else>
        <v-card-title>
          Deze route is incorrect!
        </v-card-title>
      </v-col>
    </v-row>
    <v-dialog
      v-model="endRouteDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          End Route
        </v-card-title>
        <v-card-text>
          Alle adressen zijn afgehandeld, wil je de route op "end" zetten?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="endRouteDialog = false"
          >
            NEE
          </v-btn>
          <v-btn
            color="error"
            @click="end"
            :loading="endRouteLoading"
          >
            JA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { NotifyBadgeType } from '../types/notification-badge'
import { utcToDutch } from '../helpers/timezones'

export default {
  name: 'Overview',
  components: {
    draggable
  },
  mounted () {
    this.uid = this.$route.params.uid
    this.$store.dispatch('getRoute', { self: this, uid: this.uid })
      .then(this.loadRoute)
  },
  data () {
    return {
      route: [],
      uid: null,
      total: {},
      isRouteStarted: false,
      startDialog: false,
      startLoading: false,
      changeDialog: false,
      changeLoading: false,
      drag: false,
      delayedDragging: false,
      draw: true,
      endRouteDialog: false,
      endRouteLoading: false,
      deleteDialog: false,
      deleteLoading: false,
      isRouteEnded: false,
      utcToDutch
    }
  },
  watch: {
    isDragging (newValue) {
      if (newValue) {
        this.delayedDragging = true
        return
      }
      this.$nextTick(() => {
        this.delayedDragging = false
      })
    }
  },
  computed: {
    ...mapGetters(['userRole']),
    hasOrderInTransit () {
      return this.route.filter((element) => !element.done).length
    }
  },
  methods: {
    recalculate () {
      this.changeLoading = true
      const orders = this.route.map((o) => {
        return {
          uid: o.orderUid,
          rank: o.rank,
          fixed: o.fixed
        }
      })

      this.$api.post(`/route/${this.uid}`, { orders })
        .then(() => {
          this.changeDialog = false
          this.$notify({
            message: 'Changed',
            type: NotifyBadgeType.SUCCESS
          })
          this.$store.dispatch('getRoute', { self: this, uid: this.uid })
            .then(this.loadRoute)
        })
        .finally(() => {
          this.changeLoading = false
        })
    },
    formatEstimatedDeliveryTime (e) {
      return `${utcToDutch(e.earliest).format('HH:mm')} - ${utcToDutch(e.latest).format('HH:mm')}`
    },
    lock (stop) {
      stop.fixed = !stop.fixed
      this.draw = false

      this.$nextTick(() => {
        this.draw = true
      })
    },
    back () {
      this.$router.push('/')
    },
    navigate (index) {
      if (!this.isRouteStarted) {
        this.$notify({
          message: 'Vergeet niet op start te klikken!',
          type: NotifyBadgeType.WARNING
        })
        return
      }
      this.$router.push(this.$route.fullPath + '/' + index)
    },
    start () {
      this.startLoading = true
      this.$api.post(`/v2/route/${this.uid}/start`)
        .then(() => {
          this.$notify({
            message: 'Started',
            type: NotifyBadgeType.SUCCESS
          })
          this.$router.push(`/route/${this.uid}/0`)
        })
        .finally(() => {
          this.startDialog = false
          this.startLoading = false
        })
    },
    end () {
      if (this.hasOrderInTransit) {
        this.$notify({
          title: 'Nog niet alle adressen zijn bezorgd',
          type: NotifyBadgeType.WARNING
        })
        return
      }
      this.endRouteLoading = true
      this.$api.post(`/route/${this.uid}/end`)
        .then(() => {
          this.$notify({
            message: 'Ended',
            type: NotifyBadgeType.SUCCESS
          })
          this.endRouteDialog = false
          this.isRouteEnded = true
        })
        .finally(() => {
          this.endRouteLoading = false
        })
    },
    loadRoute () {
      this.route.length = 0

      this.route.push(...this.$store.state.route)
      this.isRouteStarted = this.$store.state.isRouteStarted
      this.isRouteEnded = this.$store.state.isRouteEnded
      this.endRouteDialog = !this.hasOrderInTransit && !this.isRouteEnded
      this.total = {}
      this.route.forEach((s) => {
        s.items.forEach((i) => {
          if (!this.total[i.name]) {
            this.total[i.name] = {
              amount: 0,
              description: i.description
            }
          }
          this.total[i.name].amount += i.amount
        })
        s.fixed = false
      })
    },
    getFirstTimeMessage (firstTimeSource) {
      if (firstTimeSource === 'webshop') {
        return 'Webshop order!'
      }

      if (firstTimeSource === 'bozu') {
        return 'Hardseltzer.nl'
      }
      return 'First time order!'
    },
    deleteRoute () {
      this.deleteLoading = true
      this.$api.delete(`/route/${this.uid}`)
        .then(() => {
          this.$notify({
            message: 'Deleted',
            type: NotifyBadgeType.SUCCESS
          })
          this.$router.push('/')
        })
        .finally(() => {
          this.deleteDialog = false
          this.deleteLoading = false
        })
    }
  }
}
</script>

<style scoped>
.list-group-item {
  padding-bottom: 0.3rem;
  border: 1px solid #e8e8e8;
}
.blurry-text {
  color: transparent;
  text-shadow: 0 0 6px #000;
}

.disable-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>
