import Vue from 'vue'
import { Category, ShopManagerItem } from '../types/shop-manager'

export default Vue.extend({
  data () {
    return {
      categories: [
        {
          name: 'Pils',
          id: 1
        },
        {
          name: 'Witbier',
          id: 2
        },
        {
          name: 'Zomers',
          id: 3
        },
        {
          name: 'Seltzer',
          id: 4
        },
        {
          name: 'Fris',
          id: 5
        },
        {
          name: 'Koffie',
          id: 6
        },
        {
          name: 'null',
          id: 7
        }
      ] as Category[],
      items: [] as ShopManagerItem[]
    }
  }
})
