import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import http from './plugins/http'
import error from './plugins/error'
import VueNotify from 'vue-notifyjs'

Vue.use(VueNotify, {
  timeout: 2000,
  horizontalAlign: 'right',
  verticalAlign: 'top'
})

Vue.config.productionTip = false

Vue.prototype.$api = http
Vue.prototype.$error = error

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
