import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Overview from '../views/Overview.vue'
import Stop from '../views/Stop.vue'
import DeliveryDates from '../views/DeliveryDates.vue'
import Reschedule from '../views/Reschedule.vue'
import ShopManager from '../views/ShopManager.vue'
import ShopItemsPosition from '../views/ShopItemsPosition.vue'
import ShopCategory from '../views/ShopCategory.vue'
import CreateOrders from '../views/CreateOrders.vue'
import PageNotFound from '../views/PageNotFound.vue'
import CancelOrder from '../views/CancelOrder.vue'
import Deposits from '../views/Deposits.vue'
import { systemAxios } from '../plugins/http'
import { redirectAuth } from '../plugins/auth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: logout
  },
  {
    path: '/delivery-dates',
    name: 'DeliveryDates',
    component: DeliveryDates,
    meta: {
      privilege: ['manages', 'admin']
    }
  },
  {
    path: '/reschedule',
    name: 'Reschedule',
    component: Reschedule,
    meta: {
      privilege: ['manages', 'admin']
    }
  },
  {
    path: '/create-orders',
    name: 'CreateOrders',
    component: CreateOrders,
    meta: {
      privilege: ['manages', 'admin']
    }
  },
  {
    path: '/cancel-order',
    name: 'CancelOrder',
    component: CancelOrder,
    meta: {
      privilege: ['admin']
    }
  },
  {
    path: '/deposits',
    name: 'Deposits',
    component: Deposits,
    meta: {
      privilege: ['manages', 'admin']
    }
  },
  {
    path: '/shop-manager',
    name: 'ShopManager',
    component: ShopManager,
    meta: {
      privilege: ['manages', 'admin']
    }
  },
  {
    path: '/shop-manager/position',
    name: 'ShopItemsPosition',
    component: ShopItemsPosition,
    meta: {
      privilege: ['manages', 'admin']
    }
  },
  {
    path: '/shop-manager/category',
    name: 'ShopCategory',
    component: ShopCategory,
    meta: {
      privilege: ['manages', 'admin']
    }
  },
  {
    path: '/route/:uid',
    name: 'Overview',
    component: Overview
  },
  {
    path: '/route/:uid/:stop',
    name: 'Stop',
    component: Stop
  },
  {
    path: '/*',
    name: 'PageNotFound',
    component: PageNotFound
  }

]

function logout (to: any, from: any, next: any) {
  systemAxios.post('/delete-session')
    .then(() => {
      redirectAuth()
    })
    .catch(() => {
      next({
        path: '/404'
      })
    })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
