





























































































import Vue from 'vue'
import { Response } from '../types/response'

export interface DepositOverview {
  total: number;
  totalPrice: string;
}

export interface DepositHouse {
    uid: string,
    amount: number,
    name: string,
    address: string,
    postal: string
    orderUid: string,
    city: string,
    price: number
}

export default Vue.extend({
  name: 'Deposits',
  data () {
    return {
      overview: {} as DepositOverview,
      deposits: [] as DepositHouse[],
      headers: [
        { text: 'Order', value: 'orderUid' },
        { text: 'Amount', value: 'amount' },
        { text: 'Price', value: 'price' },
        { text: 'Address', value: 'address' },
        { text: 'Postal', value: 'postal' },
        { text: 'City', value: 'city' }
      ],
      selected: [] as DepositHouse[],
      refundDialog: false as boolean,
      refundLoading: false as boolean,
      errors: [] as any[]
    }
  },
  mounted () {
    this.getDeposits()
  },
  methods: {
    getDeposits () {
      this.$api.get('/deposits').then(({ data }: Response<DepositHouse[] & DepositOverview>) => {
        const { overview, deposits } = data.data
        this.overview = overview as DepositOverview
        this.deposits = deposits as DepositHouse[]
      })
    },
    refund () {
      if (!this.selected.length) {
        return
      }

      this.refundLoading = true

      this.$api.post('/deposits/refund', { deposits: this.selected.map((i: DepositHouse) => i.uid) })
        .then(({ data }: Response<any[]>) => {
          this.errors = data.data.errors
          this.selected = [] as DepositHouse[]
          this.getDeposits()
        })
        .finally(() => {
          this.refundLoading = false
          this.refundDialog = false
        })
    }
  }
})
