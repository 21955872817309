<template>
    <v-container class="mt-10 text-center">
        <div class="text-h4 mb-5">
            <v-icon x-large color="#f76808">
                mdi-exclamation-thick
            </v-icon>
            Pagina niet gevonden
        </div>
        <div>
            Oeps! Het lijkt erop dat deze pagina niet bestaat.
        </div>
        <div>
            <a
                style="color: #f76808; text-decoration: none;"
                @click="$router.go(-1)"
            >
                Klik hier om terug te gaan.
            </a>
        </div>
    </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
