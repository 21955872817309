














































































































import { mapGetters } from 'vuex'
import Vue, { PropType } from 'vue'
import DialogItem from './DialogItem.vue'
import DialogImages from './DialogImages.vue'
import { ItemFilter, ItemFilterForm } from '../../types/shop-manager'

export default Vue.extend({
  name: 'ShopFilter',
  props: {
    filters: {
      required: true,
      type: Object as PropType<() => ItemFilter>
    },
    clearFilters: {
      required: false,
      type: Boolean
    }
  },
  components: {
    DialogItem,
    DialogImages
  },
  data () {
    return {
      checkboxCategory: [] as string[],
      checkboxTags: [] as string[],
      name: '' as string,
      dialogItem: false as boolean,
      dialogImage: false as boolean
    }
  },
  computed: {
    ...mapGetters(['userRole']),
    hasPrivilege () : boolean {
      return !!(Object.keys(this.userRole).length && !this.userRole.privileges.admin)
    }
  },
  watch: {
    checkboxCategory () {
      const filter : ItemFilterForm = {
        category: this.checkboxCategory,
        tags: this.checkboxTags,
        name: this.name
      }
      this.$emit('onFilter', filter)
    },
    checkboxTags () {
      const filter : ItemFilterForm = {
        category: this.checkboxCategory,
        tags: this.checkboxTags,
        name: this.name
      }
      this.$emit('onFilter', filter)
    },
    name () {
      const filter : ItemFilterForm = {
        category: this.checkboxCategory,
        tags: this.checkboxTags,
        name: this.name
      }
      this.$emit('onFilter', filter)
    },
    clearFilters (value) {
      if (value) {
        this.checkboxCategory = []
        this.checkboxTags = []
        this.name = ''
        this.$emit('update:clearFilters', false)
      }
    }
  },
  methods: {
    closeFilterMobile () : void {
      this.$emit('onCloseFilterMobile')
    },
    openCreateItem () : void {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.closeFilterMobile()
      }
      this.dialogItem = true
    },
    onItemCreated () {
      this.$emit('onItemCreatedFilter')
    }
  }
})
