



















































































































































































































import Vue from 'vue'
import { DeliveryDate } from '../types/delivery-dates'
import { Tags } from '../types/shop-manager'
import { Response } from '../types/response'
import { NotifyBadgeType } from '../types/notification-badge'
import { dayjs, utcToDutch } from '../helpers/timezones'

export interface FormDelivery {
  tagUid: string,
  date: string,
  startTime: string,
  endTime: string,
  closeTime: string
}

type DisplayDeliveryDate = {
  dateDisplay: string,
  deliveryTimeblockDisplay: string,
  closingTimeDisplay: string
} & DeliveryDate

export default Vue.extend({
  data () {
    return {
      deliveryDates: [] as DeliveryDate[],
      displayDates: [] as DisplayDeliveryDate[],
      dateMenu: false as boolean,
      startMenu: false as boolean,
      endMenu: false as boolean,
      closeMenu: false as boolean,
      tags: [] as Tags[],
      form: {
        tagUid: '0jhrn09cmktg0ci',
        date: new Date().toISOString().split('T')[0],
        startTime: '17:00',
        endTime: '20:00',
        closeTime: '17:00'
      } as FormDelivery
    }
  },
  mounted () {
    this.getDates()
    this.getTags()
  },
  watch: {
    'form.tagUid' (newValue) {
      const filterDates = this.deliveryDates.filter((d) => {
        return d.tagUID === newValue
      })
      this.displayDates = [...filterDates.map((e: DeliveryDate) => {
        return {
          UID: e.UID,
          date: e.date,
          closingTime: e.closingTime,
          endDeliveryTime: e.endDeliveryTime,
          startDeliveryTime: e.startDeliveryTime,
          tag: e.tag,
          tagUID: e.tagUID,
          dateDisplay: utcToDutch(e.date).format('YYYY-MM-DD'),
          deliveryTimeblockDisplay: `${utcToDutch(e.startDeliveryTime).format('HH:mm')} - ${utcToDutch(e.endDeliveryTime).format('HH:mm')}`,
          closingTimeDisplay: `${utcToDutch(e.closingTime).format('HH:mm')}`
        }
      })]
    }
  },
  methods: {
    empty () : void {
      this.form = {
        tagUid: '',
        date: '',
        startTime: '',
        endTime: '',
        closeTime: ''
      }
    },
    createDate () : void {
      const date = dayjs.utc(this.form.date).format()
      const startTime = dayjs(date).tz().set('hour', parseInt(this.form.startTime.split(':')[0])).set('minute', parseInt(this.form.startTime.split(':')[1])).utc().format()
      const endTime = dayjs(date).tz().set('hour', parseInt(this.form.endTime.split(':')[0])).set('minute', parseInt(this.form.endTime.split(':')[1])).tz().utc().format()
      const closeTime = dayjs(date).tz().set('hour', parseInt(this.form.closeTime.split(':')[0])).set('minute', parseInt(this.form.closeTime.split(':')[1])).tz().utc().format()
      const tagUid = this.form.tagUid

      this.$api.post(`/v2/delivery-date/${tagUid}`, { startTime, endTime, closeTime }).then(() => {
        this.$notify({
          message: 'Created',
          type: NotifyBadgeType.SUCCESS
        })
        this.getDates()
      })
    },
    getDates () : void {
      this.deliveryDates = []

      this.$api.get('/v2/delivery-date').then(({ data }: Response<DeliveryDate[]>) => {
        this.deliveryDates = data.data.dates

        const filterDates = this.deliveryDates.filter((d) => {
          return d.tagUID === this.form.tagUid
        })
        this.displayDates = []
        this.displayDates.push(...filterDates.map((e: DeliveryDate) => {
          return {
            UID: e.UID,
            date: e.date,
            closingTime: e.closingTime,
            endDeliveryTime: e.endDeliveryTime,
            startDeliveryTime: e.startDeliveryTime,
            tag: e.tag,
            tagUID: e.tagUID,
            dateDisplay: utcToDutch(e.date).format('YYYY-MM-DD'),
            deliveryTimeblockDisplay: `${utcToDutch(e.startDeliveryTime).format('HH:mm')} - ${utcToDutch(e.endDeliveryTime).format('HH:mm')}`,
            closingTimeDisplay: `${utcToDutch(e.closingTime).format('HH:mm')}`
          }
        }))
      })
    },
    removeDate (uid: string | number) : void {
      this.$api.delete(`/v2/delivery-date/${uid}`).then(() => {
        this.$notify({
          message: 'Removed',
          type: NotifyBadgeType.SUCCESS
        })
        this.getDates()
      })
    },
    closeDate (uid: string | number) : void {
      this.$api.patch(`/v2/delivery-date/${uid}/close`).then(() => {
        this.$notify({
          message: 'Closed',
          type: NotifyBadgeType.SUCCESS
        })
        this.getDates()
      })
    },
    getTags () {
      this.tags.length = 0
      this.$api.get('/shop-manager/tags').then(({ data }: Response<Tags[]>) => {
        const tags : Tags[] = data.data.tags
        this.tags = tags
        this.form.tagUid = tags[0].uid
        this.$store.dispatch('getShopTags', { tags })
      })
    }
  }
})
