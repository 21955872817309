

























































import Vue, { VueConstructor } from 'vue'
import { mapGetters } from 'vuex'
import privileges from '../helpers/privileges'
import { AdminItem } from '../types/admin-nav'

export default (Vue as VueConstructor<Vue & InstanceType<typeof privileges>>).extend({
  name: 'AdminNav',
  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [privileges],
  data () {
    return {
      tab: null as string | null | undefined,
      items: [
        {
          name: 'Reschedule',
          route: '/reschedule',
          privilege: ['manages', 'admin']
        },
        {
          name: 'Delivery dates',
          route: '/delivery-dates',
          privilege: ['manages', 'admin']
        },
        {
          name: 'Shop Manager',
          route: '/shop-manager',
          privilege: ['manages', 'admin']
        },
        {
          name: 'Order',
          route: '/create-orders',
          privilege: ['manages', 'admin']
        },
        {
          name: 'Cancel',
          route: '/cancel-order',
          privilege: ['admin']
        },
        {
          name: 'Deposits',
          route: '/deposits',
          privilege: ['manages', 'admin']
        }
      ] as AdminItem[],
      drawer: false as boolean
    }
  },
  created () {
    if (this.isMobile) {
      const logoutItem : AdminItem = {
        name: 'Logout',
        route: '/logout',
        privilege: ['login']
      }
      this.items.push(logoutItem)
    }
    this.$api.get('/me')
  },
  computed: {
    ...mapGetters(['userRole'])
  },
  methods: {
    checkTabPrivilege (privilege: string[]) {
      let hasPrivilege = false
      privilege.forEach(element => {
        hasPrivilege = this.userRole.privileges[element] || hasPrivilege
      })
      return hasPrivilege
    }
  }
})
