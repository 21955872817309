




















































































import Vue from 'vue'
import { Category } from '../types/shop-manager'
import { Response } from '../types/response'
import { NotifyBadgeType } from '../types/notification-badge'

export default Vue.extend({
  name: 'ShopCategory',
  data () {
    return {
      categories: [] as Category[],
      categoryField: {
        name: '',
        id: 0
      } as Category,
      loadingBtn: false as boolean,
      loading: true as boolean,
      loadingSkeletons: 6 as number
    }
  },
  computed: {
    isEdit (): boolean {
      return !!this.categoryField.id
    }
  },
  created () {
    this.getCategories()
  },
  methods: {
    edit (value : Category) : void {
      this.categoryField = value
    },
    clear () : void {
      this.categoryField = {
        name: '',
        id: 0
      }
    },
    save (): void {
      if (this.categoryField.id) {
        this.update()
        return
      }
      this.create()
    },
    create (): void {
      this.loadingBtn = true
      this.$api.post('/shop-manager/category', this.categoryField).then(() => {
        this.$notify({
          message: 'Created',
          type: NotifyBadgeType.SUCCESS
        })
        this.clear()
        this.getCategories()
      }).finally(() => {
        this.loadingBtn = false
      })
    },
    update (): void {
      this.loadingBtn = true
      this.$api.put('/shop-manager/category', this.categoryField).then(() => {
        this.$notify({
          message: 'Updated',
          type: NotifyBadgeType.SUCCESS
        })
        this.clear()
        this.getCategories()
      }).finally(() => {
        this.loadingBtn = false
      })
    },
    getCategories () {
      this.loading = true
      this.$api.get('/shop-manager/category').then(({ data }: Response<Category[]>) => {
        this.categories = data.data.categories
      }).finally(() => {
        this.loading = false
      })
    },
    deleteCategory (category: Category) {
      this.$api.delete(`/shop-manager/category/${category.id}`, category).then(() => {
        this.$notify({
          message: 'Deleted',
          type: NotifyBadgeType.SUCCESS
        })
        this.getCategories()
      })
    }
  }
})
