var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Open Orders ")]),_c('v-card-text',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"sort-by":"startDeliveryTime","search":_vm.search,"item-key":"uid","show-select":"","items-per-page":-1,"hide-default-footer":""},on:{"click:row":_vm.openDialog,"input":_vm.handleInput},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.location.street) + " " + (item.location.number) + " " + (item.location.addition)))+" ")]}},{key:"item.time_since_order",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calcTimeSinceOrder(item.created))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.utcToDutch(item.created).format('DD/MM/YYYY, HH:mm'))+" ")]}},{key:"item.startDeliveryTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTimeblockTime(item))+" ")]}}],null,true),model:{value:(_vm.selectedData),callback:function ($$v) {_vm.selectedData=$$v},expression:"selectedData"}}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5 orange darken-3"},[_vm._v(" Details "+_vm._s(_vm.dialog.row.uid)+" ")]),_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_vm._v("Consumer:")]),_c('v-list-item-content',[_vm._v(_vm._s(_vm.dialog.row.consumerName))])],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("Phone:")]),_c('v-list-item-content',[_vm._v(_vm._s(_vm.dialog.row.consumerPhone))])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.dialog.row.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_vm._v(_vm._s(item.name)+":")]),_c('v-list-item-content',[_vm._v(_vm._s(item.amount))])],1)}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog.open = false}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)],1),_c('v-fab-transition',[(_vm.selectedData && _vm.selectedData.length)?_c('v-btn',{staticStyle:{"color":"white"},attrs:{"fixed":"","color":"#e95e38","fab":"","dark":"","bottom":"","right":"","loading":_vm.loadingRouteBtn},on:{"click":_vm.createRoutes}},[_c('v-icon',[_vm._v("mdi-truck-delivery")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }