
























































































































import Vue from 'vue'
import { Response } from '../types/response'
import { Tags } from '../types/shop-manager'
import { OrderByUidResponse, SelectedItem, DeliveryDate } from '../types/order'
import { NotifyBadgeType } from '../types/notification-badge'
import { utcToDutch } from '../helpers/timezones'

export default Vue.extend({
  name: 'CancelOrder',
  data () {
    return {
      order: {
        items: [] as SelectedItem[],
        tag: {} as Tags,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        street: '',
        number: null,
        addition: '',
        postal: '',
        city: '',
        deliveryDate: {} as DeliveryDate,
        displayDate: '',
        displayTimeblock: ''
      },
      loadingSearchOrder: false as boolean,
      orderUid: '' as string,
      hasOrderLoaded: false as boolean,
      cancelDialog: false as boolean,
      cancelLoading: false as boolean
    }
  },
  watch: {
    orderUid (value) {
      this.hasOrderLoaded = false
      this.orderUid = value.toUpperCase()
    }
  },
  methods: {
    getOrderByUid () {
      this.loadingSearchOrder = true
      this.$api.get(`/v2/order/${this.orderUid}`).then(({ data }: Response<OrderByUidResponse>) => {
        const { order } = data.data
        this.order.items = order.basket
        this.order.firstName = order.consumer.firstName
        this.order.lastName = order.consumer.lastName
        this.order.phoneNumber = order.consumer.phone
        this.order.email = order.consumer.email
        this.order.addition = order.location.addition
        this.order.number = order.location.number
        this.order.postal = order.location.postal
        this.order.street = order.location.street
        this.order.city = order.location.city
        this.order.deliveryDate = order.deliveryDate
        this.hasOrderLoaded = true
        this.order.displayDate = utcToDutch(this.order.deliveryDate.date).format('DD-MM-YYYY')
        this.order.displayTimeblock = `${utcToDutch(this.order.deliveryDate.startDeliveryTime).format('HH:mm')} - ${utcToDutch(this.order.deliveryDate.endDeliveryTime).format('HH:mm')}`
      }).finally(() => {
        this.loadingSearchOrder = false
      })
    },
    cancel () {
      this.cancelLoading = true
      this.$api.delete(`/order/cancel/${this.orderUid}`).then(({ data }: Response<{message: string}>) => {
        const { message } = data.data
        this.$notify({
          message,
          type: NotifyBadgeType.SUCCESS
        })
        this.hasOrderLoaded = false
        this.orderUid = ''
      }).finally(() => {
        this.cancelLoading = false
        this.cancelDialog = false
      })
    }
  }
})
