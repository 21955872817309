







































































import Vue from 'vue'
import { RescheduleOrder, RescheduledHeaders } from '../types/reschedule'
import { Response } from '../types/response'
import { NotifyBadgeType } from '../types/notification-badge'
import * as dayjs from 'dayjs'
import 'dayjs/locale/nl'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import { utcToDutch } from '../helpers/timezones'
dayjs.extend(utc)
dayjs.extend(tz)
dayjs.tz.setDefault('Europe/Amsterdam')

export interface DeliveryDate {
  UID: string | number,
  closingTime: string,
  date: string,
  endDeliveryTime: string,
  startDeliveryTime: string,
  tagUID: string | number,
  timeblock?: string,
  dateFormatted?: string
}

export default Vue.extend({
  data () {
    return {
      rescheduledOrders: [] as RescheduleOrder[],
      rescheduleSelected: [] as RescheduleOrder[],
      rescheduleDateMenu: false as boolean,
      newDate: new Date().toISOString().split('T')[0] as string,
      rescheduledHeaders: [
        { text: 'Huis', value: 'name' },
        { text: 'Address', value: 'address' },
        { text: 'Postal', value: 'postal' },
        { text: 'Prev. Date', value: 'deliveryDate' },
        { text: 'Contact', value: 'contactName' },
        { text: 'Email', value: 'contactEmail' },
        { text: 'Phone', value: 'contactPhone' },
        { text: 'City/Tag', value: 'tag' }
      ] as RescheduledHeaders[],
      tagSelected: '' as string,
      timeblocks: [] as DeliveryDate[],
      timeblockSelected: {} as DeliveryDate
    }
  },
  mounted () {
    this.getRescheduled()
  },
  watch: {
    rescheduleSelected () {
      this.getTimeblocksAvailable()
    },
    newDate () {
      this.getTimeblocksAvailable()
    }
  },
  methods: {
    reschedule () : void {
      if (!this.rescheduleSelected.length) {
        this.$notify({
          message: 'Select at least one checkbox to reschedule.',
          type: NotifyBadgeType.WARNING
        })
        return
      }
      if (!Object.keys(this.timeblockSelected).length) {
        this.$notify({
          message: 'Select at least one timeblock to reschedule.',
          type: NotifyBadgeType.WARNING
        })
        return
      }

      const objRequest = {
        dateUid: this.timeblockSelected.UID,
        orders: [...new Set(this.rescheduleSelected.map((o) => o.uid))]
      }

      this.$api.post('/order/reschedule', objRequest).then(() => {
        this.$notify({
          message: 'Rescheduled',
          type: NotifyBadgeType.SUCCESS
        })
        this.getRescheduled()
      })
    },
    getRescheduled () {
      this.$api.get('/order/rescheduled').then(({ data }: Response<RescheduleOrder[]>) => {
        this.rescheduledOrders = data.data.orders
      })
    },
    checkSelectedOrder () {
      if (this.rescheduleSelected.length && this.newDate) {
        const tags: string[] = this.rescheduleSelected.map((element: RescheduleOrder) => {
          return element.tagUid
        })
        const uniqueTags = [...new Set(tags)]
        if (uniqueTags.length > 1) {
          this.$notify({
            message: 'Select only one city to get the timeblocks!',
            type: NotifyBadgeType.WARNING
          })
          return ''
        }
        return uniqueTags[0]
      }
      return ''
    },
    getTimeblocksAvailable () {
      this.tagSelected = this.checkSelectedOrder()
      if (!this.tagSelected) {
        return
      }
      this.$api.get(`/v2/delivery-date/${this.tagSelected}/${dayjs.utc(this.newDate).format()}`).then(({ data }: Response<DeliveryDate[]>) => {
        this.timeblocks = data.data.dates.map((e) => {
          return {
            ...e,
            timeblock: `${utcToDutch(e.startDeliveryTime).format('HH:mm')} - ${utcToDutch(e.endDeliveryTime).format('HH:mm')}`
          }
        })
      })
    }
  }
})
