import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import { redirectAuth } from './auth'

const systemAxios = axios.create({
  baseURL:
    process.env.VUE_APP_BACKEND_URL,
  timeout: 25000,
  withCredentials: true
})

systemAxios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    redirectAuth()
  }

  return Promise.reject(error)
})

Vue.use(VueAxios, systemAxios)

export { systemAxios }
export default systemAxios
