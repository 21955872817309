import { NotifyBadgeType } from '../types/notification-badge'

export default (self: any, error: any) => {
  if (!error || !error.response) {
    console.log(error)
    throw new Error(error)
  }

  if (Array.isArray(error.response.data)) {
    for (const message of error.response.data) {
      self.$notify({
        message: message.msg,
        type: NotifyBadgeType.WARNING
      })
    }
  } else if (error.response.data.error) {
    self.$notify({
      message: error.response.data.error,
      type: NotifyBadgeType.WARNING
    })
  } else if (error.response.data.message) {
    self.$notify({
      message: error.response.data.message,
      type: NotifyBadgeType.WARNING
    })
  } else {
    self.$notify({
      message: 'ERROR',
      type: NotifyBadgeType.WARNING
    })
  }
}
