<template>
  <v-app>
    <!-- <notifications
      transition-name="notification-list"
      transition-mode="out-in"
    /> -->
<!--    <v-app-bar-->
<!--      app-->
<!--      color="primary"-->
<!--      dark-->
<!--    >-->
<!--      <div class="d-flex align-center">-->
<!--        <v-img-->
<!--          alt="Vuetify Logo"-->
<!--          class="shrink mr-2"-->
<!--          contain-->
<!--          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"-->
<!--          transition="scale-transition"-->
<!--          width="40"-->
<!--        />-->

<!--        <v-img-->
<!--          alt="Vuetify Name"-->
<!--          class="shrink mt-1 hidden-sm-and-down"-->
<!--          contain-->
<!--          min-width="100"-->
<!--          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"-->
<!--          width="100"-->
<!--        />-->
<!--      </div>-->

<!--      <v-spacer></v-spacer>-->

<!--      <v-btn-->
<!--        href="https://github.com/vuetifyjs/vuetify/releases/latest"-->
<!--        target="_blank"-->
<!--        text-->
<!--      >-->
<!--        <span class="mr-2">Latest Release</span>-->
<!--        <v-icon>mdi-open-in-new</v-icon>-->
<!--      </v-btn>-->
<!--    </v-app-bar>-->
    <!-- <notifications
      transition-name="notification-list"
      transition-mode="out-in"
    /> -->
    <v-app-bar
      app
      elevation="4"
      color="#f76808"
      class="mb-5"
    >
      <v-img
        maxWidth="100"
        src="@/assets/logo.png"
        @click="redirectHome"
        style="cursor:pointer;"
      ></v-img>
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-spacer></v-spacer>
        <AdminNav :isMobile="true"/>
      </template>
      <template v-else>
        <AdminNav />
        <v-spacer></v-spacer>
        <v-btn
          text
          icon
          color="white"
          to="/logout"
          id="logoutButton"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-main>
       <notifications
          transition-name="notification-list"
          transition-mode="out-in"
        />
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { io } from 'socket.io-client'
import AdminNav from './components/AdminNav'

// var x = document.getElementById('demo')
// function getLocation () {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(showPosition)
//   } else {
//     x.innerHTML = 'Geolocation is not supported by this browser.'
//   }
// }
//
// function showPosition (position) {
//   x.innerHTML = 'Latitude: ' + position.coords.latitude +
//     '<br>Longitude: ' + position.coords.longitude
// }

export default {
  name: 'App',
  components: {
    AdminNav
  },
  data: () => ({
    //
  }),
  mounted () {
    // this.setupWebsocketConnection()
    // eslint-disable-next-line
    const self = this
    this.$api.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      self.$error(self, error)
      return Promise.reject(error)
    })
  },
  methods: {
    setupWebsocketConnection () {
      this.$auth.get('/auth/session')
        .then((response) => {
          const socketProtocol = window.location.protocol.includes('https')
            ? 'wss'
            : 'ws'
          const host =
            process.env.NODE_ENV === 'production'
              ? 'route.turff.nl'
              : process.env.NODE_ENV === 'development'
                ? 'localhost'
                : 'dev.route.turff.nl'

          const port = process.env.NODE_ENV === 'development' ? ':8999' : ''
          const socket = io(`${socketProtocol}://${host}${port}`, {
            reconnection: true,
            path: '/ws',
            auth: {
              token: response.data['admin-session']
            }
          })

          socket.on('connect', () => {
            console.log('connected')

            navigator.geolocation.watchPosition(
              (a) => {
                console.log(a)
                socket.emit('update-location', {
                  coords: {
                    accuracy: a.coords.accuracy,
                    altitude: a.coords.altitude,
                    altitudeAccuracy: a.coords.altitudeAccuracy,
                    heading: a.coords.heading,
                    latitude: a.coords.latitude,
                    longitude: a.coords.longitude,
                    speed: a.coords.speed
                  },
                  timestamp: a.timestamp
                })
              },
              (error) => {
                console.log(error)
              }
            )
          })
        })
    },
    redirectHome () {
      if (this.$route.fullPath === '/') return
      this.$router.push('/')
    }
  }
}
</script>
<style src="vue-notifyjs/themes/default.css"></style>
<style>

</style>
