




























































































































































import axios from 'axios'
import { mapGetters } from 'vuex'
import Vue, { VueConstructor } from 'vue'
import shopManagerMock from '../helpers/shopManagerMock'
import defaultImg from '@/assets/no-image.png'
import ShopFilter from '../components/shop-manager/ShopFilter.vue'
import DialogItem from '../components/shop-manager/DialogItem.vue'
import { ShopManagerItem, ItemFilter, ItemFilterForm, Tags } from '../types/shop-manager'
import { Response } from '../types/response'

export default (Vue as VueConstructor<Vue & InstanceType<typeof shopManagerMock>>).extend({
  name: 'ShopManager',
  mixins: [shopManagerMock],
  data () {
    return {
      itemsGroup: [] as Array<Array<ShopManagerItem>>,
      filters: {} as ItemFilter,
      orderByItems: ['Position', 'Name A-Z', 'Name Z-A'] as string[],
      orderBy: 'Position' as string,
      clearFilters: false as boolean,
      dialog: false as boolean,
      dialogItem: false as boolean,
      itemToEdit: [] as ShopManagerItem[],
      defaultImg: defaultImg as any,
      loadingSkeletons: 6 as number,
      loading: false as boolean,
      attrsSkeleton: {
        boilerplate: true,
        elevation: 2
      } as any,
      items: [] as ShopManagerItem[],
      showScrollBtn: false as boolean
    }
  },
  components: {
    ShopFilter,
    DialogItem
  },
  mounted () {
    this.getItems()
    window.addEventListener('scroll', () => {
      this.showScrollBtn = window.scrollY >= 300
    })
  },
  computed: {
    ...mapGetters(['shopTags'])
  },
  watch: {
    orderBy () {
      this.setOrder()
    }
  },
  methods: {
    getTag (uid : string) : string {
      const tag = this.shopTags.filter((element: Tags) => element.uid === uid)[0]
      return tag.name
    },
    formatCurrency (value : number) : string {
      const euro = value / 1000
      return euro.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    },
    setItemsGroup (array : ShopManagerItem[]) : void {
      const keyName = 'name'
      const resultArr = array.reduce((result, currentValue) => {
        if (!result[currentValue[keyName]]) {
          result[currentValue[keyName]] = []
        }
        result[currentValue[keyName]].push(currentValue)
        return result
      }, [])
      this.itemsGroup = Object.values(resultArr)
    },
    itemDisabled (item : ShopManagerItem[]) : boolean {
      const itemsDisabled = item.filter(element => element.disabled)

      return itemsDisabled.length === item.length
    },
    createFilters () {
      const categories : string[] = this.getUniqueValuesArray(this.items.map(element => element.category))

      const names : string[] = this.getUniqueValuesArray(this.items.map(element => element.name))

      const tagsId : string[] = this.getUniqueValuesArray(this.items.map(element => element.tagUid))
      const tagsName : string[] = tagsId.map(element => this.getTag(element))

      this.filters = { categories, names, tagsName }
    },
    getUniqueValuesArray (array : string[]) : string[] {
      return [...new Set(array)]
    },
    setDefaultImg (e: any) : void {
      e.target.src = defaultImg
    },
    onFilter (value : ItemFilterForm) : void {
      if (!value.category && !value.name && !value.tags) {
        this.setItemsGroup(this.items)
        return
      }

      let filtered = this.items
      if (value.category && value.category.length) {
        filtered = filtered.filter(element => value.category.includes(element.category))
      }

      if (value.name && value.name.length) {
        filtered = filtered.filter(element => {
          const itemName = element.name.toLowerCase()
          const filterName = value.name.toLowerCase()
          return itemName.includes(filterName)
        })
      }

      if (filtered.length) {
        this.setItemsGroup(filtered)
        if (value.tags && value.tags.length) {
          this.itemsGroup = this.itemsGroup.filter(element => {
            return element.filter(item => value.tags.includes(this.getTag(item.tagUid))).length
          })
        }
      }
    },
    setOrder () : void {
      if (this.orderBy === 'Name A-Z') {
        this.itemsGroup.sort((a, b) => {
          if (a[0].name < b[0].name) {
            return -1
          }
          if (a[0].name > b[0].name) {
            return 1
          }
          return 0
        })
      }

      if (this.orderBy === 'Name Z-A') {
        this.itemsGroup.sort((a, b) => {
          if (a[0].name > b[0].name) {
            return -1
          }
          if (a[0].name < b[0].name) {
            return 1
          }
          return 0
        })
      }

      if (this.orderBy === 'Position') {
        this.setItemsGroup(this.items)
        this.clearFilters = true
      }
    },
    editItem (item : ShopManagerItem[]) : void {
      this.itemToEdit = item
      this.dialogItem = true
    },
    getItems () : void {
      this.loading = true
      const requests = [
        this.$api.get('/shop-manager/tags'),
        this.$api.get('/shop-manager/items')
      ]
      axios.all(requests).then(axios.spread((res1, res2) => {
        const { data: tagsRes } : Response<Tags[]> = res1
        const { data: itemsRes } : Response<ShopManagerItem[]> = res2

        const tags : Tags[] = tagsRes.data.tags
        const items : ShopManagerItem[] = itemsRes.data.items
          .filter(item => tags.some(tag => tag.uid === item.tagUid)) // only show selected tags

        this.items = items
        this.$store.dispatch('getShopTags', { tags })
        this.setItemsGroup(items)
        this.createFilters()
      })).finally(() => {
        this.loading = false
      })
    },
    onItemCreated () {
      this.getItems()
    },
    scrollToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
})
