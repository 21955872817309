<template>
  <v-container>
    <v-row class="flex-row">
      <v-col cols="12" align-self="start" class="my-5">
        <v-row>
          <v-btn @click="back"  large elevation="4" dark icon color="error" style="margin-left: 15px">
            <v-icon>fa fa-arrow-left</v-icon>
          </v-btn>
          <v-spacer />
          <span v-if="target.status === 'delivered'" style="color: grey; margin:auto">Bezorgd!</span>
          <span v-if="target.status === 'rescheduled'" style="color: grey; margin:auto">Niet thuis</span>
          <v-spacer />
          <v-btn v-if="(target.status === 'delivered' || target.status === 'rescheduled') && locked" @click="locked = false" class="mr-3">
            <v-icon left>
              fa fa-lock
            </v-icon>
            {{ 'Unlock' }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="target.maps">
      <v-col cols="12" v-if="!hidePrivateInfo()">
        <v-card>
          <v-card-text>
            <v-list-item two-line>
              <v-list-item-icon >
                <v-btn elevation="2" fab :href="target.maps" target="_blank" style="text-decoration: none;">
                  <v-icon style="color: #e95e38">fa fa-map-marker</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{target.adres.streetdata}}</v-list-item-title>
                <v-list-item-subtitle>
                  {{target.adres.postal}}, Nederland
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-icon >
                <v-btn elevation="2" fab :href="'tel:' + target.contact.phone" target="_blank" style="text-decoration: none;">
                  <v-icon style="color: #e95e38">fa fa-phone</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{target.contact.name}}</v-list-item-title>
                <v-list-item-subtitle>
                  <a :href="'tel:' + target.contact.phone" target="_blank" style="text-decoration: none;">
                    {{target.contact.phone}}
                  </a>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-dialog
                v-model="userDialog"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#e95e38"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    Andere bewoners bekijken
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 orange darken-3">
                    Andere bewoners
                  </v-card-title>

                  <v-card-text>
                    <v-list>
                      <v-list-item two-line v-for="(user, index) of target.users" :key="index">
                        <v-list-item-icon >
                          <v-btn elevation="2" fab :href="'tel:' + user.phone" target="_blank" style="text-decoration: none;">
                            <v-icon style="color: #e95e38">fa fa-phone</v-icon>
                          </v-btn>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{user.firstName || 'unknown'}} {{user.lastName}}</v-list-item-title>
                          <v-list-item-subtitle>
                            <a :href="'tel:' + user.phone" target="_blank" style="text-decoration: none;">
                              {{user.phone}}
                            </a>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
<!--      <v-col cols="12">-->
<!--        <v-card-->
<!--          color="warning"-->
<!--        >-->
<!--          <v-card-title>-->
<!--            <v-icon style="color: black">-->
<!--              fa fa-id-card-->
<!--            </v-icon>-->
<!--            <v-spacer />-->
<!--            <span style="color: black">-->
<!--              ID CHECK-->
<!--            </span>-->
<!--            <v-spacer />-->

<!--          </v-card-title>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-snackbar-->
<!--      v-model="snackbar"-->
<!--      color="warning"-->
<!--      timeout="-1"-->
<!--      centered-->
<!--      dark-->

<!--      >-->
<!--        <v-icon style="color: indigo">-->
<!--          fa fa-id-card-->
<!--        </v-icon>-->
<!--        <span style="color: indigo">-->
<!--          ID CHECK-->
<!--        </span>-->
<!--        <template v-slot:action="{ attrs }">-->
<!--          <v-btn-->
<!--            color="indigo"-->
<!--            text-->
<!--            v-bind="attrs"-->
<!--            @click="snackbar = false"-->
<!--          >-->
<!--            Close-->
<!--          </v-btn>-->
<!--        </template>-->
<!--      </v-snackbar>-->
    <v-col cols="12">
        <v-card>
          <v-card-title>
            Bestelling: {{target.orderUid}}
            <v-spacer></v-spacer>
            <img
              :src="nixImg"
              height="21px"
              width="auto"
            />
          </v-card-title>
          <v-divider v-if="target.firstTimeSource" />
          <div v-if="target.firstTimeSource" class="pl-4 text-center">
            <v-icon color="green accent-4" large>mdi-alert-octagram</v-icon>
            <span class="black--text">
              <b>{{ getFirstTimeMessage(target.firstTimeSource) }}</b>
            </span>
          </div>
          <v-divider />
          <div v-for="(item, index) of target.items" :key="index">
            <v-list-item>
              <v-list-item-icon>
                {{item.amount}}
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
                <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </div>
          <v-card-actions>
            <v-btn :disabled="resultForm.handIns < 1 || locked || !maxValue" :dark="resultForm.handIns > 0 && !locked" @click="decrement" icon color="error" elevation="4" style="margin: 15px">
              <v-icon>fa fa-minus</v-icon>
            </v-btn>
            <v-spacer />
            <v-text-field
              label="# Kratten ingeleverd"
              v-model="resultForm.handIns"
              type="number"
              :disabled="locked || !maxValue"
              :rules="[rules.max, rules.required]"
              hide-spin-buttons
            />
            <v-spacer />
            <v-btn @click="increment" icon color="success" elevation="4" :dark="!locked" :disabled="locked || !maxValue" style="margin: 15px">
              <v-icon>fa fa-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row cols="12" v-else>
      <v-card-title>
        Deze stop is incorrect!
      </v-card-title>
    </v-row>

    <v-row rows="10">
      <v-btn :disabled="locked" @click="notAvailable" large elevation="4" :dark="!locked" color="error" style="margin: 15px">
        Niet Thuis
      </v-btn>

      <v-spacer />
      <v-btn :disabled="locked || (!isCrateValueValid && maxValue)" @click="delivered" large elevation="4" color="success" style="margin: 15px">
        Bezorgd
      </v-btn>
    </v-row>
<!--    <v-btn @click="getPrevious" absolute bottom left large elevation="4" dark color="error" style="margin: 15px">-->
<!--      Niet Thuis-->
<!--    </v-btn>-->

<!--    <v-btn @click="getNext" absolute bottom right large elevation="4" dark color="success" style="margin: 15px">-->
<!--      Bezorgd-->
<!--    </v-btn>-->
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import * as dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import nix18 from '@/assets/nix18.png'

export default {
  name: 'Stop',
  components: {},
  data () {
    return {
      dialog: false,
      userDialog: false,
      snackbar: true,
      resultForm: {
        handIns: 0
      },
      target: {},
      locked: false,
      maxValue: 0,
      rules: {
        required: value => !!value || 'Vul een waarde in.',
        max: value => value <= this.maxValue || `De hoeveelheid kratten mag niet meer zijn dan ${this.maxValue}.`
      },
      nixImg: nix18
    }
  },
  mounted () {
    const uid = this.$route.params.uid
    this.$store.dispatch('getRoute', { self: this, uid })
      .then(this.loadStop)
  },
  computed: {
    isCrateValueValid () {
      return this.resultForm.handIns <= this.maxValue
    },
    ...mapGetters(['userRole'])
  },
  methods: {
    loadStop () {
      const stopId = this.$route.params.stop
      this.target = this.$store.state.route[stopId]
      console.log(this.target)

      if (this.target.done) {
        this.locked = true
      }
      this.resultForm.handIns = this.target.deposit_amount
      this.maxValue = this.target.maxReturns
    },
    delivered () {
      this.locked = true
      this.$api.post(`/order/${this.target.orderUid}/delivered`, this.resultForm)
        .then(() => {
          this.locked = false
          this.$router.push(this.$route.fullPath.substring(0, this.$route.fullPath.lastIndexOf('/')))
        })
        .catch(() => {
          this.locked = false
        })
    },
    notAvailable () {
      this.locked = true
      this.$api.post(`/order/${this.target.orderUid}/unavailable`)
        .then(() => {
          this.locked = false
          this.$router.push(this.$route.fullPath.substring(0, this.$route.fullPath.lastIndexOf('/')))
        })
        .catch(() => {
          this.locked = false
        })
    },
    back () {
      this.$router.push(this.$route.fullPath.substring(0, this.$route.fullPath.lastIndexOf('/')))
    },
    increment () {
      this.resultForm.handIns++
    },
    decrement () {
      this.resultForm.handIns--
    },
    hidePrivateInfo () {
      if (this.userRole.privileges.manages || this.userRole.privileges.admin) {
        return false
      }

      dayjs.extend(timezone)
      dayjs.tz.setDefault('Europe/Amsterdam')
      dayjs.extend(isSameOrBefore)
      dayjs.extend(customParseFormat)
      if (this.target.delivered && dayjs(dayjs.utc(this.target.delivered).add(1, 'd')).isSameOrBefore(dayjs.utc())) {
        return true
      }

      return false
    },
    getFirstTimeMessage (firstTimeSource) {
      if (firstTimeSource === 'webshop') {
        return 'Webshop order!'
      }

      if (firstTimeSource === 'bozu') {
        return 'Hardseltzer.nl'
      }
      return 'First time order!'
    }
  }
}
</script>
