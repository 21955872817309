<template>
  <v-container align-items="start">
    <v-row id="homeRow">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      label="Date"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="dateMenu = false"
                    :first-day-of-week="1"
                    color="#30446F"
                  />
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="tag"
                  :items="tags"
                  label="City/Tag"
                  item-text="tag"
                  item-value="tag"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="#f76808"
            centered
            dark
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-orders">
              ORDERS
            </v-tab>
            <v-tab href="#tab-routes">
              ROUTES
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
      <template v-if="tab === 'tab-orders'">
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <order-overview
            :title="'Open'"
            :total="total"
          />
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <div v-if="drawSelected">
            <order-overview
              :title="'Selected'"
              :total="totalSelected"
            />
          </div>

        </v-col>
        <v-col cols="12">
          <orders-table
            :date="date"
            :orders="orders"
            :selected="selected"
            @selected="select"
            @reload="reload"
            @onRouteCreated="onRouteCreated"
            @onOpenRoute="saveNavigation"
          />
        </v-col>
      </template>
      <template v-if="tab === 'tab-routes'">
        <v-col cols="12">
          <routes
            :routes="routes"
            @onOpenRoute="saveNavigation"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import OrdersTable from '@/components/overview/OrdersTable'
import OrderOverview from '@/components/overview/OrderOverview'
import Routes from '@/components/overview/Routes'

export default {
  name: 'Home',
  components: { Routes, OrderOverview, OrdersTable },
  data: () => ({
    date: '',
    newDate: '',
    dateMenu: false,

    orders: [],
    selected: [],
    total: {},
    overview: [],

    tags: [],
    tag: '',

    routes: [],
    drawSelected: true,
    totalSelected: {},
    tab: null,
    cancelToken: null,
    cancelTokenRoute: null
  }),
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'Overview') {
        vm.tab = 'tab-routes'
      }
    })
  },
  mounted () {
    const temp = new Date()
    this.date = temp.getFullYear() + '-' + (temp.getMonth() + 1) + '-' + String(temp.getDate()).padStart(2, '0')
    this.newDate = this.date
    // this.getRescheduled()
    this.getTags()
    this.getLastNavigation()
  },
  watch: {
    date () {
      if (this.date && this.tag) {
        this.getOrders(this.tag)
        this.getRoutes()
      }
    },
    tag (newValue) {
      if (this.date && this.tag) {
        this.getOrders(newValue)
        this.getRoutes()
      }
    },
    selected (newValue) {
      this.recalculate(newValue)
    }
  },
  methods: {
    recalculate (newValue) {
      this.drawSelected = false
      this.totalSelected = null
      const totals = {}

      for (const order of newValue) {
        const items = order.items

        for (const item of items) {
          if (!totals[order.location.city]) {
            totals[order.location.city] = {}
          }

          if (!totals[order.location.city][item.name]) {
            totals[order.location.city][item.name] = {
              city: order.location.city,
              item: item.name,
              total: 0
            }
          }

          totals[order.location.city][item.name].total += item.amount
        }
      }

      this.totalSelected = totals
      this.drawSelected = true
    },
    select (selected) {
      this.selected.length = 0
      this.selected.push(...selected)
    },
    reload () {
      this.selected.length = 0
      this.getOrders(this.tag)
      this.getRoutes()
    },
    getTags () {
      this.tags.length = 0
      this.$api.get('/tag')
        .then((response) => {
          this.tags.push(...response.data)
          this.tag = this.tags[0].tag
        })
    },
    getRoutes () {
      this.routes.length = 0
      if (this.cancelTokenRoute) {
        this.cancelTokenRoute.cancel()
      }
      this.cancelTokenRoute = axios.CancelToken.source()
      this.$api.get(`/route/all/${this.date}`, { cancelToken: this.cancelTokenRoute.token })
        .then((response) => {
          this.routes.push(...response.data.routes)
        })
    },
    getOrders (tag) {
      this.orders.length = 0
      this.total.length = 0
      this.selected.length = 0
      this.overview.length = 0
      if (this.cancelToken) {
        this.cancelToken.cancel()
      }
      this.cancelToken = axios.CancelToken.source()
      this.$api.get(`/v2/order/${tag}/${this.date}`, { cancelToken: this.cancelToken.token })
        .then((response) => {
          this.orders.push(...response.data.orders)

          const overview = {}

          const totals = {}

          let size = 0

          for (const order of response.data.orders) {
            const items = response.data.orderItems.filter((o) => o.orderUid === order.uid)

            let orderSize = 0
            order.items = items

            for (const item of items) {
              if (!totals[order.location.city]) {
                totals[order.location.city] = {}
              }

              if (!totals[order.location.city][item.name]) {
                totals[order.location.city][item.name] = {
                  city: order.location.city,
                  item: item.name,
                  total: 0
                }
              }

              totals[order.location.city][item.name].total += item.amount
              orderSize += item.amount
            }

            if (size + orderSize < 130) {
              size += orderSize
              this.selected.push(order)
            }
          }

          this.total = totals
          // this.total.push(...Object.values(totals).map((t) => Object.values(t)))
          this.overview.push(...Object.values(overview))

          this.recalculate(this.selected)
        })
    },
    saveNavigation () {
      if (sessionStorage.getItem('navigation')) {
        sessionStorage.removeItem('navigation')
      }

      const navigation = {
        date: this.date,
        tag: this.tag
      }
      sessionStorage.setItem('navigation', JSON.stringify(navigation))
    },
    getLastNavigation () {
      if (sessionStorage.getItem('navigation')) {
        const lastNavigation = JSON.parse(sessionStorage.getItem('navigation'))
        this.date = lastNavigation.date
        this.tag = lastNavigation.tag
      }
    },
    onRouteCreated () {
      this.tab = 'tab-routes'
    }
  }
}
</script>
