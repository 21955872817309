import Vue from 'vue'
import Vuex from 'vuex'
import http from '../plugins/http'
import { Tags } from '../types/shop-manager'
// import { role } from '../helpers/role'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    route: [] as Array<any>,
    userRole: {},
    shopTags: [] as Tags[],
    isRouteStarted: false as boolean,
    isRouteEnded: false as boolean
  },
  getters: {
    userRole: state => state.userRole,
    shopTags: state => state.shopTags
  },
  mutations: {
    // eslint-disable-next-line
    SET_ROUTE (state, { uid, orders, items, users, start, end, created, date }) {
      state.route = []
      state.isRouteStarted = !!start
      state.isRouteEnded = !!end
      for (const order of orders) {
        state.route.push({
          orderUid: order.uid,
          status: order.status,
          done: order.status === 'delivered' || order.status === 'rescheduled',
          icon: order.status === 'delivered' ? 'fa fa-check' : order.status === 'rescheduled' ? 'fa fa-times' : '',
          adres: {
            streetdata: `${order.location.street} ${order.location.number}${order.location.addition ? ` ${order.location.addition}` : ''}`,
            postal: order.location.postal,
            city: order.location.city
          },
          maps: `https://maps.google.com/?q=${order.location.city}+${order.location.street}+${order.location.number}+${order.location.addition}`,
          // maps: `https://maps.google.com/?q=${order.location.lat},${order.location.long}`,
          contact: {
            name: order.consumerName,
            phone: order.consumerPhone
          },
          total: 0,
          items: [],
          users: users.filter((u: any) => u.uid === order.uid && u.phone),
          deposit_amount: order.deposit ? order.deposit.amount : 0,
          changeable: order.deposit ? order.deposit.refundStatus === 'review' : true,
          maxReturns: order.maxReturns,
          delivered: order.delivered,
          estimated: order.estimated,
          firstTimeSource: order.firstTimeSource || null
        })
      }

      for (const item of items) {
        const order = state.route.find((s: any) => s.orderUid === item.orderUid)
        order.total += item.amount
        order.items.push(item)
      }
    },
    setUserRole (state, value) {
      Vue.set(state, 'userRole', value)
    },
    setShopTags (state, value: Tags[]) {
      Vue.set(state, 'shopTags', value)
    }
  },
  actions: {
    getRoute ({ commit }, { uid }) {
      return http.get(`/v2/route/${uid}`)
        .then((response) => {
          commit('SET_ROUTE', response.data)
          console.log(response.data)
        })
    },
    getUserRole ({ commit }, { user }) {
      commit('setUserRole', user)
    },
    getShopTags ({ commit }, { tags }: { tags: Tags[]}) {
      commit('setShopTags', tags)
    }
  },
  modules: {
  }
})
