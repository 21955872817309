<template>
  <v-card>
    <v-card-title>
      Routes
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="routeHeaders"
        :items="routes"
        class="elevation-1"
        multi-sort
        item-key="uid"
        @click:row="open"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:[`item.start`]="{ item }">
          {{ item.start ? utcToDutch(item.start).format('DD/MM/YYYY, HH:mm') : 'not started' }}
        </template>
        <template v-slot:[`item.end`]="{ item }">
          {{ item.end ? utcToDutch(item.end).format('DD/MM/YYYY, HH:mm') : 'not ended' }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-icon :color="getIconColor(item.status)">{{getIconName(item.status)}}</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { utcToDutch } from '../../helpers/timezones'
export default {
  name: 'Routes',
  props: {
    routes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      routeHeaders: [
        { text: 'Status', value: 'status' },
        { text: 'Route', value: 'uid' },
        { text: 'City', value: 'city' },
        { text: 'Delivery Agent', value: 'deliveryAgent.name' },
        // { text: 'Datum', value: 'date' },
        { text: 'Totaal', value: 'total' },
        { text: 'Start', value: 'start' },
        { text: 'End', value: 'end' }
      ],
      utcToDutch
    }
  },
  methods: {
    open (item) {
      this.$emit('onOpenRoute')
      this.$router.push(`/route/${item.uid}`)
    },
    getIconName (status) {
      if (status === 'scheduled') {
        return 'mdi-calendar'
      }
      if (status === 'transit') {
        return 'mdi-truck-fast'
      }
      return 'mdi-check-bold'
    },
    getIconColor (status) {
      if (status === 'scheduled') {
        return 'gray'
      }
      if (status === 'transit') {
        return 'orange'
      }
      return 'green'
    }
  }
}
</script>

<style scoped>

</style>
