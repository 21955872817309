<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div
        v-for="(city, index) of Object.keys(total)"
        :key="index"
      >
        {{ city }}:<br>
        <v-list-item
          v-for="(item, index) of Object.keys(total[city])"
          :key="index"
        >
          {{ item }}: {{ total[city][item].total }}<br>
        </v-list-item>
        <br>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'OrderOverview',
  props: {
    title: {
      type: String,
      required: true
    },
    total: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
