






















































































import Vue, { VueConstructor } from 'vue'
import draggable from 'vuedraggable'
import defaultImg from '@/assets/no-image.png'
import shopManagerMock from '../helpers/shopManagerMock'
import { ShopManagerItem, ShopManagerPositionItem } from '../types/shop-manager'
import { Response } from '../types/response'
import { NotifyBadgeType } from '../types/notification-badge'

export default (Vue as VueConstructor<Vue & InstanceType<typeof shopManagerMock>>).extend({
  name: 'ShopItemsPosition',
  mixins: [shopManagerMock],
  components: {
    draggable
  },
  data () {
    return {
      itemsGroup: [] as Array<Array<ShopManagerItem>>,
      defaultImg: defaultImg as any,
      loadingSkeletons: 6 as number,
      loading: false as boolean,
      attrsSkeleton: {
        boilerplate: true,
        elevation: 2
      } as any,
      loadingSaveBtn: false as boolean
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    setItemsGroup (array : ShopManagerItem[]) : void {
      const keyName = 'name'
      const resultArr = array.reduce((result, currentValue) => {
        if (!result[currentValue[keyName]]) {
          result[currentValue[keyName]] = []
        }
        result[currentValue[keyName]].push(currentValue)
        return result
      }, [])
      this.itemsGroup = Object.values(resultArr)
    },
    setDefaultImg (e: any) : void {
      e.target.src = defaultImg
    },
    getItems () : void {
      this.loading = true
      this.$api.get('/shop-manager/items').then(({ data }: Response<ShopManagerItem[]>) => {
        const items : ShopManagerItem[] = data.data.items
        this.setItemsGroup(items)
      }).finally(() => {
        this.loading = false
      })
    },
    savePosition () : void {
      this.loadingSaveBtn = true
      const positionItems: ShopManagerPositionItem[] = this.itemsGroup.map((element: Array<ShopManagerItem>) => {
        const item: ShopManagerPositionItem = {
          id: element[0].id,
          name: element[0].name,
          category: element[0].category
        }
        return item
      })
      this.$api.post('/shop-manager/save-positions', positionItems).then((response: any) => {
        if (response && response.data) {
          this.$notify({
            message: 'Positions saved!',
            type: NotifyBadgeType.SUCCESS
          })
        }
      })
        .finally(() => {
          this.loadingSaveBtn = false
        })
    }
  }
})
