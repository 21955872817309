import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(tz)
dayjs.tz.setDefault('Europe/Amsterdam')
dayjs.locale('nl')

export { dayjs }

export function utcToDutch (x: any) {
  return dayjs(x).tz()
}
